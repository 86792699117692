import React from 'react'

const NavigationDots = ({ active }) => (
    <div className='app__navigation'>
        {['خانه', 'درباره ما', 'نمونه کار', 'مهارت ها', 'تماس با ما'].map((item, index) => (
            <a
             href={`#${item}`} 
             key={item + index}
             className='app__navigation-dot'
             style={active === item ? { backgroundColor: '#126760' } : { }}
            />
        ))}
    </div>
  );


export default NavigationDots